import React from "react";
import { Loading } from ".";
import useWebview from "../../../hooks/useWebview";
import Portal from "../Portal";

interface FullPageLoadingProps {
  text?: string;
}

const FullPageLoading: React.FC<FullPageLoadingProps> = ({ text }) => {
  const { inWebview } = useWebview();

  return (
    <Portal>
      <section
        className="fixed inset-0 z-90 flex flex-col justify-center items-center bg-blur"
        style={{ backgroundColor: inWebview ? "#191514" : "" }}
      >
        <Loading
          className="w-18 h-18 rounded-full shadow-lg p-4"
          style={{ backgroundColor: inWebview ? "#2e2928" : "white" }}
        />
        {text && (
          <span
            className="font-semibold mt-4 text-text"
            style={{ color: inWebview ? "#f0ece0" : "" }}
          >
            {text}
          </span>
        )}
      </section>
    </Portal>
  );
};

export default FullPageLoading;
