import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import React from "react";
import "react-dates/lib/css/_datepicker.css";
import "react-datetime/css/react-datetime.css";
import ReactDOM from "react-dom";
import "react-quill/dist/quill.snow.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "react-spring-bottom-sheet/dist/style.css";
import App from "./App";
import "./assets/styles/main.css";

if (process.env.NODE_ENV !== "development")
  Sentry.init({
    dsn: "https://fc52f98d020e4d0ab9e7f510a1bb5531@o402184.ingest.sentry.io/5651855",
    integrations: [new Integrations.BrowserTracing()],
  });

if (
  process.env.NODE_ENV === "production" &&
  !localStorage.getItem("zozoConsole")
) {
  window.console.log = () => {};
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);
