import React from "react";
import Lottie from "lottie-react";

import logoLoadingAnimation from "./../../../assets/lottie/logo-loading.json";

interface LoadingProps {
  className: string;
  style?: React.CSSProperties;
}

const Loading: React.FC<LoadingProps> = ({ className, style }) => {
  return (
    <Lottie
      animationData={logoLoadingAnimation}
      className={className}
      style={style}
    />
  );
};

export default Loading;
