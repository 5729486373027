import React, { ComponentType, ReactElement } from "react";
import { GeneralObject } from "../definitions/general";

export const getChildrenProps = (children: Array<ReactElement | null>) => {
  const _children: GeneralObject[] = [];
  React.Children.forEach(children, (element) => {
    if (element == null) return;
    if (!React.isValidElement(element)) return;

    const props: any = element.props;
    const childData = {
      ...props,
      key: element.key,
      children: [],
    };
    _children.push(childData);
  });

  return _children;
};

export function retry(
  fn: () => Promise<any>,
  retriesLeft = 5,
  interval = 1000
) {
  return new Promise<{ default: ComponentType<any> }>((resolve, reject) => {
    fn()
      .then(resolve)
      .catch((error: any) => {
        setTimeout(() => {
          if (retriesLeft === 1) {
            // reject('maximum retries exceeded');
            reject(error);
            return;
          }

          // Passing on "reject" is the important part
          retry(fn, retriesLeft - 1, interval).then(resolve, reject);
        }, interval);
      });
  });
}

export const lazyLoadComponent = (importFunction: () => Promise<any>) =>
  React.lazy(() => retry(importFunction));
